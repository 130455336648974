export const data = [{
    company:"Arctic devs",
    time : "Nov/2021--Jan/2022",
    role: "Full Stack Developer Intern",
    points:["Built and shipped frontend for a consultancy service that provides therapy sessions for corporate employees",
    "Write modern, performant, maintainable code for a diverse array of client",
       "created the website for the startup I worked for which was used by them till date" ],
},{
    company:"TVAST it solutions",
    time : "August/2022--Nov/2022",
    role:"Software engineer intern",
    points:["collabrated with core team on building webapp for large scale construction firm which provides all features needed in one-place",
    "Worked on government project of bangladesh.which is used between source of communication between public and governement ministory",
    "Communicate with multi-disciplinary teams of engineers, designers, producers, and clients on a daily basis",
    "Worked with core team members in a deverse array of projects."],

}
]